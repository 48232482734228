export const repos = [
  {
    title: "vaultwarden",
    description:
      "Unofficial Bitwarden compatible server written in Rust, formerly known as bitwarden_rs",
    language: "Rust",
    stars: 39600,
    forks: 1900,
  },
  {
    title: "bw_web_builds",
    description: "Web vault builds for vaultwarden",
    language: "Shell",
    stars: 247,
    forks: 92,
  },
  {
    title: "rust_async_ftp",
    description:
      "Fork of https://crates.io/crates/ftp with tokio async support",
    language: "Rust",
    stars: 22,
    forks: 14,
  },
  {
    title: "multiview_gpu",
    description: "TensorFlow GPU-based implementation of multiview methods",
    language: "Python",
    stars: 8,
    forks: 0,
  },
];
