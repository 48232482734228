const MagnifyingGlassIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "MagnifyingGlassIcon",
  strokeColor = "#FDF9ED",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      width={props.size || props.width || 24}
      height={props.size || props.height || 24}
      {...props}
      viewBox="0 0 28 28"
      focusable="false"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M17.692 18.515a8.307 8.307 0 100-16.615 8.307 8.307 0 000 16.615zM20 7.753a2.77 2.77 0 00-4.265-.425M2 25.9l10.301-9.359"
      ></path>
    </svg>
  );
};

export default MagnifyingGlassIcon;
