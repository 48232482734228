// HydraButton.tsx
import { Card, CardBody, CardHeader, CardFooter } from "@heroui/react";

export const HydraCard = (props: any) => {
  return (
    <Card
      className={`rounded-[2rem] bg-primary p-[1rem] text-background ${props.hasMaxW ? "max-w-[22rem]" : "max-w-full"} ${props.bordered ? "border-[0.2rem] border-background" : ""} ${props.className || ""} ${props.hasShadow ? "!shadow-[inset_0_0_20px_#BB84FF]" : "!shadow-none"} overflow-hidden`}
    >
      {props.header ? <CardHeader>{props.header}</CardHeader> : <></>}
      <CardBody className="overflow-hidden">{props.children}</CardBody>
      {props.footer ? <CardFooter>{props.footer}</CardFooter> : <></>}
    </Card>
  );
};
