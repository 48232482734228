import * as React from "react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import TestimonialsCard from "@/components/TestimonialsCard";
import {
  testimonialCompaniesProfiles,
  testimonialDevelopersProfiles,
} from "@/components/TestimonialsCard/testimonialProfiles";

export function DevCarousel() {
  return (
    <div className="flex w-full items-center justify-center">
      <Carousel className="w-full max-w-xs">
        <CarouselContent>
          <CarouselItem>
            <TestimonialsCard {...testimonialDevelopersProfiles[0]} index={0} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialDevelopersProfiles[1]} index={1} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialDevelopersProfiles[2]} index={2} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialDevelopersProfiles[3]} index={3} />
          </CarouselItem>
          {/* Add more CarouselItems as needed */}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
}

export function CompanyCarousel() {
  return (
    <div className="flex w-full items-center justify-center">
      <Carousel className="w-full max-w-xs">
        <CarouselContent>
          <CarouselItem>
            <TestimonialsCard {...testimonialCompaniesProfiles[0]} index={0} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialCompaniesProfiles[1]} index={1} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialCompaniesProfiles[2]} index={2} />
          </CarouselItem>
          <CarouselItem>
            <TestimonialsCard {...testimonialCompaniesProfiles[3]} index={3} />
          </CarouselItem>
          {/* Add more CarouselItems as needed */}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
}
