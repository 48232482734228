"use client";

import { useTranslations } from "next-intl";

import { Link } from "@/i18n/routing";
import Image from "next/image";

import styles from "@/styles/page.module.scss";
import {
  useScroll,
  useMotionValueEvent,
  useTransform,
  motion,
} from "framer-motion";

import { AnimatedCard } from "@/components/AnimatedCard";
import SignUpForm from "@/components/SignUpForm";
import LogoGrid from "@/components/ClientsGrid";
import HydraIcon from "@/components/Icons/HydraIcon";
import { HydraButton } from "@/components/HydraButton";

import BranchIcon from "@/components/Icons/BranchIcon";
import { HydraCard } from "@/components/HydraCard";
import { useEffect, useRef, useState } from "react";
import ParticlesBg from "@/components/Particles";
import { ProfileCard } from "@/components/ProfileCard";
import { profiles } from "@/components/ProfileCard/profiles";
import ScreeningCard from "@/components/ScreeningCard";
import { screeningProfile } from "@/components/ScreeningCard/screenProfile";
import TestimonialsCard from "@/components/TestimonialsCard";
import {
  testimonialCompaniesProfiles,
  testimonialDevelopersProfiles,
} from "@/components/TestimonialsCard/testimonialProfiles";
import locationIcon from "@/public/location-icon.svg";
import branchIcon from "@/public/branch-icon.svg";
import ApplicationCard from "@/components/ApplicationCard";
import MagnifyingGlassIcon from "@/components/Icons/MagnifyingGlassIcon";
import GitHubIconDark from "@/components/Icons/GitHubIconDark";
import { RepositoryCard } from "@/components/RepositoryCard";
import { repos } from "@/components/RepositoryCard/repos";
import { Footer } from "@/components/Footer";
import { CompanyCarousel, DevCarousel } from "@/components/Carousel";

export default function Home() {
  const t = useTranslations("Landing");
  const [activeSection, setActiveSection] = useState<
    "companies" | "developers" | null
  >("companies");

  const heroRef = useRef(null);
  const { scrollY } = useScroll({
    target: heroRef,
    offset: ["start start", "end start"],
  });

  const scale = useTransform(scrollY, [0, 500], [1, 0.8]);
  const opacity = useTransform(scrollY, [0, 500], [1, 0]);

  const [isSticky, setIsSticky] = useState(null);
  const testimonialsSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const testimonialsDevSectionRef = useRef(null);
  const applySectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const rect = testimonialsSectionRef.current.getBoundingClientRect();
          const offsetTop = rect.top + window.scrollY;
          setIsSticky(offsetTop);
        } else {
          setIsSticky(null);
        }
      },
      {
        root: null,
        threshold: 0,
      },
    );
    if (contactSectionRef.current) {
      observer.observe(contactSectionRef.current);
    }
    return () => {
      if (contactSectionRef.current) {
        observer.unobserve(contactSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const rect =
            testimonialsDevSectionRef.current.getBoundingClientRect();
          const offsetTop = rect.top + window.scrollY;
          setIsSticky(offsetTop);
        } else {
          setIsSticky(null);
        }
      },
      {
        root: null,
        threshold: 0,
      },
    );
    if (applySectionRef.current) {
      observer.observe(applySectionRef.current);
    }
    return () => {
      if (applySectionRef.current) {
        observer.unobserve(applySectionRef.current);
      }
    };
  }, []);

  return (
    <main className="animate-fadeIn">
      <div
        className="mb-[-2rem] bg-gradient-light bg-custom-size bg-[-30vw_10vh] bg-no-repeat pb-[2rem] xl:bg-[-50vw_10vh]"
        style={{ "--gradient-angle": "210deg" } as React.CSSProperties}
      >
        <motion.div ref={heroRef} style={{ scale, opacity }}>
          <section id="hero">
            <div className="absolute inset-0 flex w-full content-center items-center overflow-hidden">
              <div className="relative h-full w-full">
                <div className="absolute top-[35vh] h-full w-full md:right-auto md:top-auto md:mt-[15rem]"></div>
              </div>
            </div>
            <div className={[styles.homeSection, "flexWrap"].join(" ")}>
              <div className="flex gap-[1rem]">
                <div className={[styles.heroSectionCTA, ""].join(" ")}>
                  <h1>{t("hero-title")}</h1>
                  <h2>{t("hero-cta")}</h2>
                </div>

                <ParticlesBg />
              </div>
              <div className="mb-[1.5rem] block flex w-full items-end justify-end md:block md:hidden md:w-auto">
                <AnimatedCard
                  hasMaxW
                  rotation={12}
                  afterRotation={12}
                  rotationHover={0}
                  className="md:animate-rotateIn"
                >
                  <div className="flex h-[20vh] items-center justify-center px-[1rem] py-[1rem] pt-[1rem] md:h-[35vh] md:pb-[5rem] md:pt-[5rem]">
                    <HydraIcon
                      style={{
                        width: "auto",
                        maxWidth: "calc(100vw - 10rem)",
                        height: "100%",
                      }}
                    />
                  </div>
                </AnimatedCard>
              </div>
            </div>
          </section>
        </motion.div>
      </div>

      <section
        id="companies"
        className={activeSection === "companies" ? "" : "hidden"}
      >
        <section id="companiesHero" className="top-[4rem] md:sticky">
          <div
            className="flex min-h-[100vh] flex-col items-center rounded-b-none rounded-t-[2em] bg-gradient-purple px-8 py-16 lg:px-32"
            style={{ "--gradient-angle": "135deg" } as React.CSSProperties}
          >
            <div className="w-full flex-col">
              <div className="flex w-full justify-center gap-4">
                <HydraButton
                  autoFocus={false}
                  color="gradient"
                  hover="secondary"
                  onClick={() => setActiveSection("companies")}
                >
                  {t("companiesHero-button")}{" "}
                </HydraButton>
                <HydraButton
                  autoFocus={false}
                  color="transparent"
                  border="secondary"
                  hover="gradient"
                  onClick={() => setActiveSection("developers")}
                >
                  {t("developersHero-button")}{" "}
                </HydraButton>
              </div>
              <div className="my-[2rem] flex w-full flex-col items-center gap-8 lg:mt-[6rem] lg:flex-row lg:items-start lg:justify-center lg:gap-[12rem]">
                <div className="flex max-w-[35rem] flex-col justify-start">
                  <h1 className="mb-4 text-center text-white lg:text-left">
                    {t("companiesHero-title")}
                  </h1>
                  <h2 className="text-center text-white lg:text-left">
                    {t("companiesHero-subtitle")}
                  </h2>
                </div>
                <div>
                  <div className="bg-purple-custom mt-16 flex flex-col items-center justify-start gap-6 p-4">
                    {profiles.map((profile, index) => (
                      <ProfileCard
                        key={profile.username}
                        index={index}
                        {...profile}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="companiesScreening" className="top-[4rem] md:sticky">
          <div
            className="mt-[-2rem] flex items-start justify-center rounded-b-none rounded-t-[2em] bg-gradient-light bg-custom-size bg-[-30vw_-10vh] bg-no-repeat px-[1rem] pb-[8rem] pt-[2rem] lg:min-h-[100vh] lg:items-center lg:bg-[-50vw_-25vh] lg:py-[0]"
            style={{ "--gradient-angle": "310deg" } as React.CSSProperties}
          >
            <div className="flex flex-col-reverse gap-8 lg:flex-row lg:gap-[8rem]">
              <div className="w-fit content-end md:m-0 md:content-normal">
                <ScreeningCard className="max-h-[37.2rem] min-h-[32rem] overflow-hidden">
                  <div className="flex items-center gap-4">
                    <Image
                      src={screeningProfile.imageUrl}
                      alt={`${screeningProfile.username}'s profile picture`}
                      className="rounded-full object-cover"
                      width={48}
                      height={48}
                    />
                    <div className="flex flex-col">
                      <h3 className="text-lg font-semibold text-default-text">
                        {screeningProfile.username}
                      </h3>
                      <p className="flex items-center gap-2">
                        <GitHubIconDark className="h-[1rem] w-[1rem]" />|{" "}
                        {screeningProfile.position}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end pb-2 pr-4">
                    <p className="text-sm font-light italic">
                      64 contributions
                    </p>
                  </div>
                  {repos.map((repo, index) => (
                    <RepositoryCard key={index} {...repo} />
                  ))}
                </ScreeningCard>
              </div>

              <div className="max-w-[30rem]">
                <h1 className="text-center lg:text-left">
                  {t("companiesScreening-title")}
                </h1>
                <h2 className="text-center text-3xl lg:text-left">
                  {t("companiesScreening-subtitle")}
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section
          id="companiesTestimonials"
          ref={testimonialsSectionRef}
          style={{
            position: isSticky ? "sticky" : "relative",
            top: isSticky !== null ? `${isSticky - window.scrollY}px` : "auto",
            transition: "top 0.3s ease",
          }}
        >
          <div
            className="mt-[-2rem] rounded-[2em] rounded-b-none rounded-t-[2em] bg-gradient-purple p-6 pb-[8rem] pt-[2rem] lg:p-[2rem] lg:py-[5rem]"
            style={{ "--gradient-angle": "100deg" } as React.CSSProperties}
          >
            <div className="justify-center md:flex lg:gap-[5rem]">
              <div className="lg:max-w-[35rem]">
                <h1 className="text-center text-white md:text-left">
                  {t("companiesTestimonials-title")}
                </h1>
                <h2 className="text-center text-white md:text-left">
                  {t("companiesTestimonials-subtitle")}
                </h2>
              </div>
              <div className="relative hidden md:block">
                <Image
                  src={branchIcon.src}
                  alt="branch icon"
                  className="relative z-40 h-full w-full"
                  width={24}
                  height={24}
                />
                <div className="pointer-events-none absolute inset-0 z-0 bg-radial-gradient" />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="relative mt-[4rem] flex flex-wrap justify-center gap-4 md:mt-[12rem] md:max-w-[80rem] lg:gap-8 lg:p-16">
                <div className="block md:hidden">
                  <CompanyCarousel />
                </div>
                {testimonialCompaniesProfiles.map((profile, index) => (
                  <div
                    key={profile.name}
                    className={`relative hidden md:block ${
                      // Mobile/tablet layout
                      index === 1 || index === 3 ? "mt-[-10rem]" : ""
                    } ${
                      // Desktop layout
                      index === 2
                        ? "lg:mt-[10rem]"
                        : index === 3
                          ? "lg:mt-[-20rem]"
                          : ""
                    } ${
                      // Tablet layout
                      index === 2
                        ? "md:mt-[0.5rem]"
                        : index === 3
                          ? "md:mt-[-15rem]"
                          : ""
                    } ${
                      // Control width to create columns with larger gap
                      "w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-2rem)]"
                    }`}
                    style={{
                      filter: "drop-shadow(0 0 20px rgba(147, 51, 234, 0.3))",
                    }}
                  >
                    <TestimonialsCard index={index} {...profile} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section
          id="contact"
          ref={contactSectionRef}
          className="relative mt-[-2rem] flex min-h-[calc(100vh-4rem)] flex-col justify-between overflow-hidden rounded-b-none rounded-t-[2em] bg-gradient-purple"
          style={{ "--gradient-angle": "20deg" } as React.CSSProperties}
        >
          <div className="min-w-full flex-row flex-col items-baseline justify-center gap-8 overflow-hidden p-6 pt-[2rem] md:flex md:flex-row">
            <BranchIcon
              className="absolute left-[-25%] top-[-35%] h-[150%] w-[150%] scale-x-[-1] md:z-10"
              fill="#FDF9ED"
              stroke="#FDF9ED"
            />
            <div>
              <h1 className="max-w-[35rem] text-center text-white md:text-left">
                {t("signUp-title")}
              </h1>
              {/* <h2 className="max-w-[35rem] text-center text-xl font-medium text-white md:text-left">
                {t("signUp-cta")}
              </h2> */}
            </div>
            <SignUpForm className="z-20 my-[2rem]" />
          </div>
          <Footer className="!m-0" />
        </section>
      </section>
      <section
        id="developers"
        className={activeSection === "developers" ? "" : "hidden"}
      >
        <section id="developersHero" className="top-[4rem] md:sticky">
          <div
            className="flex min-h-[calc(100vh-2rem)] flex-col items-center rounded-b-none rounded-t-[2em] bg-gradient-purple p-8 lg:px-32 lg:py-16"
            style={{ "--gradient-angle": "0deg" } as React.CSSProperties}
          >
            <div className="flex w-full justify-center gap-4">
              <HydraButton
                autoFocus={false}
                color="transparent"
                hover="tertiary"
                border="secondary"
                onClick={() => setActiveSection("companies")}
              >
                {t("companiesHero-button")}{" "}
              </HydraButton>
              <HydraButton
                autoFocus={false}
                color="gradient"
                hover="secondary"
                border="lightpurple"
                onClick={() => setActiveSection("developers")}
              >
                {t("developersHero-button")}{" "}
              </HydraButton>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center gap-8 md:mt-[8rem] lg:mt-[10rem] lg:flex-row lg:gap-[10rem]">
              <div className="flex flex-col justify-start lg:max-w-[35rem]">
                <h1 className="mb-4 text-center text-white lg:text-left">
                  {t("developersHero-title")}
                </h1>
                <h2 className="text-center text-white lg:text-left">
                  {t("developersHero-subtitle")}
                </h2>
              </div>
              <LogoGrid />
            </div>
          </div>
        </section>
        <section
          id="developersApplication"
          className="top-[4rem] hidden md:sticky"
        >
          <div
            className="mt-[-2rem] flex items-start justify-center rounded-b-none rounded-t-[2em] bg-gradient-light bg-custom-size bg-[-30vw_-10vh] bg-no-repeat px-[1rem] pb-[8rem] pt-[2rem] lg:min-h-[100vh] lg:items-center lg:bg-[-50vw_-25vh] lg:py-[0]"
            style={{ "--gradient-angle": "135deg" } as React.CSSProperties}
          >
            <div className="flex flex-col items-center justify-center gap-8 lg:flex-row lg:items-start lg:gap-[8rem]">
              <div className="max-w-[18rem] md:max-w-[30rem]">
                <h1 className="mb-4 text-center lg:text-left">
                  {t("developersApplication-title")}
                </h1>
                <h2 className="text-center text-2xl lg:text-left lg:text-3xl">
                  {t("developersApplication-subtitle")}
                </h2>
              </div>
              <div className="w-fit content-end md:m-0 md:content-normal">
                <ApplicationCard />
              </div>
            </div>
          </div>
        </section>
        <section
          id="developersTestimonials"
          ref={testimonialsDevSectionRef}
          style={{
            position: isSticky ? "sticky" : "relative",
            top: isSticky !== null ? `${isSticky - window.scrollY}px` : "auto",
            transition: "top 0.3s ease",
          }}
        >
          <div
            className="mt-[-2rem] rounded-[2em] rounded-b-none rounded-t-[2em] bg-gradient-purple p-6 pb-[8rem] pt-[2rem] lg:p-[2rem] lg:py-[5rem]"
            style={{ "--gradient-angle": "100deg" } as React.CSSProperties}
          >
            <div className="flex flex-col-reverse justify-center lg:flex-row lg:gap-[5rem]">
              <div className="flex min-w-[20rem] flex-col gap-2">
                <ScreeningCard borderColor="border-white">
                  <div className="flex items-center justify-between gap-4">
                    <p className="font-medium">paradigmxyz/reth</p>
                    <MagnifyingGlassIcon strokeColor="#6A488E" />
                  </div>
                </ScreeningCard>
                <ScreeningCard borderColor="border-none">
                  {(() => {
                    const repositories = [
                      "a16z/jolt",
                      "alloy-rs/alloy",
                      "foundry-rs/foundry",
                    ];
                    return (
                      <div className="flex flex-col gap-2">
                        <p className="text-xs font-medium">
                          related Repositories
                        </p>
                        <div className="h-[1px] w-full bg-foreground opacity-50"></div>
                        {repositories.map((repo, index) => (
                          <div className="flex justify-between" key={index}>
                            <p className="font-medium">{repo}</p>
                            <MagnifyingGlassIcon
                              strokeColor="#6A488E"
                              className="h-4 w-4"
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })()}
                </ScreeningCard>
              </div>
              <div className="lg:max-w-[35rem]">
                <h1 className="text-center text-white lg:text-left">
                  {t("developersTestimonials-title")}
                </h1>
                <h2 className="mb-4 mt-2 text-center text-xl text-white lg:text-left lg:text-3xl">
                  {t("developersTestimonials-subtitle")}
                </h2>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="relative mt-[4rem] flex flex-wrap justify-center gap-4 md:mt-[12rem] md:max-w-[80rem] lg:gap-8 lg:p-16">
                <div className="block md:hidden">
                  <DevCarousel />
                </div>
                {testimonialDevelopersProfiles.map((profile, index) => (
                  <div
                    key={profile.name}
                    className={`relative hidden md:block ${
                      // Mobile/tablet layout
                      index === 1 || index === 3 ? "mt-[-10rem]" : ""
                    } ${
                      // Desktop layout
                      index === 2
                        ? "lg:mt-[10rem]"
                        : index === 3
                          ? "lg:mt-[-15rem]"
                          : ""
                    } ${
                      // Tablet layout
                      index === 2
                        ? "md:mt-[0.5rem]"
                        : index === 3
                          ? "md:mt-[-10rem]"
                          : ""
                    } ${
                      // Control width to create columns with larger gap
                      "w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-2rem)]"
                    }`}
                    style={{
                      filter: "drop-shadow(0 0 20px rgba(147, 51, 234, 0.3))",
                    }}
                  >
                    <TestimonialsCard index={index} {...profile} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section
          id="apply"
          ref={applySectionRef}
          className="relative mt-[-2rem] flex min-h-[calc(100vh-4rem)] flex-col justify-between overflow-hidden rounded-b-none rounded-t-[2em] bg-gradient-purple"
          style={{ "--gradient-angle": "20deg" } as React.CSSProperties}
        >
          <div className="min-h-[60vh] min-w-full flex-row flex-col items-baseline justify-center gap-8 p-6 pt-[2rem] md:flex md:flex-row">
            <BranchIcon
              className="absolute left-[-25%] top-[-35%] h-[150%] w-[150%] scale-x-[-1] md:z-10"
              fill="#FDF9ED"
              stroke="#FDF9ED"
            />
            <div>
              <h1 className="max-w-[35rem] text-center text-white md:text-left">
                {t("developersApply-title")}
              </h1>
              {/* <h2 className="max-w-[35rem] text-center text-xl font-medium text-white md:text-left">
                {t("developersApply-subtitle")}
              </h2> */}
            </div>
            <SignUpForm className="z-20 my-[2rem]" />
          </div>
          <Footer className="!m-0" />
        </section>
      </section>
    </main>
  );
}
