import React, { ReactNode } from "react";
import GitHubIcon from "../Icons/GitHubIcon";
import XIcon from "../Icons/XIcon";
import Link from "next/link";
import Image from "next/image";

interface TestimonialsCardProps {
  name: string;
  position: string;
  quote: string;
  imageUrl: string;
  githubUrl?: string;
  xUrl?: string;
  className?: string;
  index: number;
  repository?: {
    name: string;
    url: string;
  };
}

const TestimonialsCard: React.FC<TestimonialsCardProps> = ({
  name,
  position,
  quote,
  imageUrl,
  githubUrl,
  xUrl,
  repository,
  className = "",
  index,
}) => {
  return (
    <div
      className={`min-h-[30rem] rounded-[2rem] bg-foreground p-8 shadow-[inset_0_0_20px_#BB84FF] md:min-h-0 lg:max-w-[25rem] ${className}`}
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <Image
          src={imageUrl}
          alt={name}
          className="h-16 w-16 rounded-full"
          width={64}
          height={64}
        />
        <div>
          <h3 className="text-center text-2xl font-semibold text-white">
            {name}
          </h3>
          <p className="text-center text-base font-semibold text-white">
            {position}
            {repository && (
              <Link
                href={repository.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-danger"
              >
                {repository.name}
              </Link>
            )}
          </p>
        </div>
        <p className="my-2 text-center text-lg font-light text-white">
          &quot;{quote}&quot;
        </p>
        <div className="flex gap-4">
          {githubUrl && (
            <Link href={githubUrl} target="_blank" rel="noopener noreferrer">
              <GitHubIcon className="h-[1.5rem] w-[1.5rem]" />
            </Link>
          )}
          {xUrl && (
            <Link href={xUrl} target="_blank" rel="noopener noreferrer">
              <XIcon className="h-[1.5rem] w-[1.5rem]" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCard;
