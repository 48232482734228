const BranchIcon = ({
  fill = "#6A488E",
  filled = false,
  stroke = "#6A488E",
  size = undefined,
  height = undefined,
  width = undefined,
  label = "BranchIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 730 1002"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        stroke={stroke}
        strokeWidth="3"
        d="M-7 455.272l735.622 499.343M-629 147.272l735.622 499.343M384.5 721c-7-4.667-26-28.2 0-67s5-58-32-85.5M260.586 636.909c-6.319-5.553-33.969-17.815-64.717 17.341-30.749 35.157-54.73 19.841-90.869-8.78"
      ></path>
      <circle cx="264.5" cy="639.5" r="5.5" fill={fill} stroke="none"></circle>
      <circle cx="105.5" cy="646.5" r="5.5" fill={fill}></circle>
      <circle cx="350.5" cy="566.5" r="5.5" fill={fill}></circle>
      <circle cx="391.5" cy="726.5" r="5.5" fill={fill}></circle>
    </svg>
  );
};

export default BranchIcon;
