const XIcon = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "XIcon",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 44 40"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        fill="#FDF9ED"
        d="M34.653 0H41.4L26.66 16.847 44 39.772H30.422L19.788 25.868 7.62 39.772H.869l15.766-18.02L0 0h13.922l9.613 12.709L34.653 0zm-2.368 35.734h3.738L11.891 3.826H7.879l24.406 31.908z"
      ></path>
    </svg>
  );
};

export default XIcon;
