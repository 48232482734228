import React, { ReactNode } from "react";

interface ScreeningCardProps {
  children: ReactNode;
  className?: string;
  borderColor?: string;
}

const ScreeningCard: React.FC<ScreeningCardProps> = ({
  children,
  borderColor = "border-primary",
  className = "",
}) => {
  return (
    <div className={`relative`}>
      {/* Shadow card */}
      <div
        className={`absolute left-[-5px] top-[1px] z-0 h-full w-full rotate-[-2deg] rounded-2xl border-2 ${borderColor} lg:left-[-1.5rem] lg:top-[2px] lg:rotate-[-5deg]`}
      />

      {/* Main card */}
      <div
        className={`relative z-10 h-full w-full rounded-2xl bg-background p-5 shadow-[0_0_35px_5px_#BB84FF] ${className}`}
      >
        {children}
      </div>
    </div>
  );
};

export default ScreeningCard;
