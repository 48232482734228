import Image from "next/image";

const LogoGrid = () => {
  return (
    <div className="relative mx-auto grid !w-auto !max-w-3xl !grid-cols-2 gap-0">
      <div className="pointer-events-none absolute inset-0 z-0 bg-radial-gradient" />
      <div className="absolute left-1/2 top-0 h-full w-[1px] bg-white/20" />
      <div className="absolute left-0 top-1/2 h-[1px] w-full bg-white/20" />

      <div className="relative z-10 flex items-center justify-center p-12">
        <Image src="/Icons/solana.svg" alt="Solana" width={64} height={64} />
      </div>

      <div className="relative z-10 flex items-center justify-center p-12">
        <Image src="/Icons/kraken.svg" alt="Kraken" width={128} height={128} />
      </div>

      <div className="relative z-10 flex items-center justify-center p-12">
        <Image
          src="/Icons/filecoin.svg"
          alt="Filecoin"
          width={128}
          height={128}
        />
      </div>

      <div className="relative z-10 flex items-center justify-center p-12">
        <Image src="/Icons/sui.svg" alt="Sui" width={128} height={128} />
      </div>
    </div>
  );
};

export default LogoGrid;
