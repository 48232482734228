export type Profile = {
  name: string;
  username: string;
  position: string;
  experience: string;
  imageUrl: string;
  githubUrl?: string;
};

export const profiles: Profile[] = [
  {
    name: "James Carter",
    username: "@jcarter123",
    position: " Blockchain Engineer at Parity",
    experience: "Rust",
    imageUrl: "/Profiles/jcarter.png",
    githubUrl: "https://github.com/jcarter123",
  },
  {
    name: "Sarah Johnson",
    username: "@john_son_",
    position: "CTO at StarkWare",
    experience: "DeFi",
    imageUrl: "/Profiles/sjohn.png",
    githubUrl: "https://github.com/john_son_",
  },
  {
    name: "Robert Lang",
    username: "@ro.langlang98",
    position: "Head of Engineering at 0x",
    experience: "",
    imageUrl: "/Profiles/rolang.png",
    githubUrl: "https://github.com/ro.langlang98",
  },
  {
    name: "Elena Martinez",
    username: "@ella_marti.2",
    position: "Web3 Developer at Ava Labs",
    experience: "Rust",
    imageUrl: "/Profiles/ella.png",
    githubUrl: "https://github.com/ella_marti.2",
  },
];
