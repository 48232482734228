export function format_location(location: string): string | undefined {
  if (!location) {
    return undefined;
  }

  // Trim any whitespace and check if the location contains a comma
  const trimmedLocation = location.trim();
  const commaIndex = trimmedLocation.indexOf(",");

  if (commaIndex === -1) {
    // Return null if there's no comma, indicating the format is incorrect
    return location;
  }

  // Split the location string into city and country
  const city = trimmedLocation.slice(0, commaIndex).trim();
  const country = trimmedLocation.slice(commaIndex + 1).trim();

  return `${city && city !== "City not found" && city !== undefined ? `${city},` : ""} ${country}`;
}
