import Image from "next/image";
import GitHubIconDark from "../Icons/GitHubIconDark";
import Link from "next/link";

interface ProfileCardProps {
  name: string;
  username: string;
  position: string;
  experience: string;
  imageUrl: string;
  className?: string;
  style?: React.CSSProperties;
  index: number;
  githubUrl?: string;
}

export const ProfileCard = ({
  name,
  username,
  position,
  experience,
  imageUrl,
  style,
  index,
  githubUrl,
}: ProfileCardProps) => {
  return (
    <div
      style={style}
      className={`flex w-full max-w-md items-center gap-4 rounded-2xl bg-white p-4  shadow-[0_10px_15px_-3px_rgb(0_0_0_/_0.1),_0_4px_6px_-4px_rgb(0_0_0_/_0.1),_0_0_15px_rgba(255,255,255,0.5)] lg:min-w-[20rem] ${index % 2 === 0 ? "translate-x-[-2rem] md:translate-x-[-5rem] lg:translate-x-[-5rem]" : "translate-x-[2rem] md:translate-x-[5rem] lg:translate-x-[5rem]"} `}
    >
      <Image
        src={imageUrl}
        alt={`${name}'s profile picture`}
        className="h-12 w-12 rounded-full object-cover"
        width={48}
        height={48}
      />
      <div className="flex flex-col min-w-0">
        <h3 className="text-lg font-semibold text-default-text">{username}</h3>
        <div className="flex flex-nowrap items-center gap-2">
          <GitHubIconDark className="h-[1rem] w-[1rem] flex-shrink-0" />| 
          <p className="truncate">{position}</p>
        </div>
      </div>
    </div>
  );
};
