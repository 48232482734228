import { useTranslations } from "next-intl";

import { Link } from "@/i18n/routing";
import { siteConfig } from "@/config/site";

import styles from "./Footer.module.scss";
import { HydraTextIconWhite } from "@/components/Icons/HydraTextIcon";

export const Footer: React.FC<{ className?: string }> = (props) => {
  const t = useTranslations("Common"); // default namespace (optional)
  return (
    <footer className={[styles.footer, props.className].join(" ")}>
      <Link
        href={"/"}
        className={[styles.footerLogo].join(" ")}
        aria-label="Link to the home page"
      >
        <HydraTextIconWhite className="h-[3rem] w-auto" />
      </Link>
      <ul
        className={[
          styles.footerLinkList,
          "flexContainer",
          "flexRow",
          "flexWrap",
        ].join(" ")}
      >
   
        <li>
          <Link href={"/imprint"}>{t("footer-imprintLink")}</Link>
        </li>
        <li>
          <Link href={"/privacy"}>{t("footer-privacyLink")}</Link>
        </li>
      </ul>
    </footer>
  );
};
