const GitHubIconDark = ({
  fill = undefined,
  filled = false,
  size = undefined,
  height = undefined,
  width = undefined,
  label = "GitHubIconDark",
  ...props
}) => {
  return (
    <svg
      fill={filled ? fill : "none"}
      {...props}
      viewBox="0 0 43 43"
      focusable="false"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        fill="#6B478E"
        fillRule="evenodd"
        d="M21.518 0C9.619 0 0 9.854 0 22.045c0 9.745 6.163 17.994 14.713 20.913 1.07.22 1.46-.474 1.46-1.058 0-.51-.034-2.263-.034-4.088-5.986 1.314-7.233-2.628-7.233-2.628-.962-2.555-2.387-3.211-2.387-3.211-1.959-1.35.143-1.35.143-1.35 2.173.145 3.313 2.262 3.313 2.262 1.924 3.358 5.023 2.409 6.27 1.825.178-1.424.748-2.41 1.354-2.956-4.774-.512-9.797-2.41-9.797-10.877 0-2.409.855-4.38 2.209-5.913-.214-.547-.962-2.81.214-5.84 0 0 1.816-.584 5.913 2.263a20.368 20.368 0 015.38-.73c1.817 0 3.669.256 5.379.73 4.097-2.847 5.914-2.263 5.914-2.263 1.176 3.03.427 5.293.214 5.84 1.39 1.533 2.208 3.504 2.208 5.913 0 8.468-5.022 10.329-9.832 10.877.784.693 1.46 2.007 1.46 4.087 0 2.956-.035 5.33-.035 6.059 0 .584.392 1.278 1.46 1.059C36.837 40.039 43 31.789 43 22.045 43.035 9.855 33.38 0 21.518 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default GitHubIconDark;
