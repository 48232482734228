import Image from "next/image";
import { useFormatter } from "next-intl";
import { Chip } from "@heroui/react";
type RepositoryCardProps = {
  title: string;
  description: string;
  language: string;
  stars: number;
  forks: number;
};

export const RepositoryCard = ({
  title,
  description,
  language,
  stars,
  forks,
}: RepositoryCardProps) => {
  const formatter = useFormatter();
  return (
    <div className="mb-2 flex max-w-[32rem] flex-col items-start justify-center rounded-2xl border-2 border-primary p-4 pr-8">
      <h3 className="text-lg font-semibold text-default-text">{title}</h3>
      <p className="text-sm text-default-text">{description}</p>
      <div className="mt-2 flex items-center gap-8">
        <Chip className="bg-primary text-xs text-white">{language}</Chip>
        <p className="flex items-center gap-1">
          <Image src="/Icons/star.svg" alt="stars" width={16} height={16} />
          {`${stars > 999 ? formatter.number(stars / 1000) + "k" : stars}`}
        </p>
        <p className="flex items-center gap-1">
          <Image src="/Icons/fork.svg" alt="forks" width={12} height={12} />
          {`${forks > 999 ? formatter.number(forks / 1000) + "k" : forks}`}
        </p>
      </div>
    </div>
  );
};
