export type Profile = {
  name: string;
  username: string;
  position: string;
  imageUrl: string;
  githubUrl: string;
};

export const screeningProfile: Profile = {
  name: "James Carter",
  username: "@jcarter123",
  position: " Engineering Manager at Chainlink Labs",
  imageUrl: "/Profiles/jcarter123.png",
  githubUrl: "https://github.com/jcarter123",
};
