"use client";

import { useState } from "react";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";

import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenu,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  NavbarMenuItem,
} from "@heroui/navbar";
import { Link } from "@/i18n/routing";

import { siteConfig } from "@/config/site";
import HydraTextIcon from "@/components/Icons/HydraTextIcon";

export const Navbar = () => {
  const t = useTranslations("Common"); // default namespace (optional)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

  const handleNavClick = (e: React.MouseEvent, href: string) => {
    if (href.includes("#")) {
      e.preventDefault();
      const elementId = href.split("#")[1];

      if (pathname === "/") {
        // On homepage, just scroll
        document
          .getElementById(elementId)
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        // On other pages, navigate to homepage first
        window.location.href = `/#${elementId}`;
      }
    }
  };

  return (
    <NextUINavbar
      maxWidth="full"
      className="z-50 px-2"
      position="sticky"
      isBlurred={false}
      onMenuOpenChange={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
      isMenuOpen={isMenuOpen}
    >
      <NavbarContent className="basis-full px-0" justify="start">
        <NavbarBrand as="li" className="max-w-fit gap-3">
          <Link
            className="flex items-center gap-1"
            href="/"
            aria-label="Click link to go to landing page home"
          >
            <HydraTextIcon
              style={{ height: "2rem", width: "auto", fill: "#55555" }}
            />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent justify="end">
        <ul className="align-end ml-2 hidden justify-end gap-4 md:flex lg:flex">
          {siteConfig.navItems.map((item) => (
            <NavbarItem key={item.href}>
              <Link
                className="text-primary hover:underline hover:opacity-100"
                href={item.href}
                onClick={(e) => handleNavClick(e, item.href)}
                aria-label={item.ariraLabel}
              >
                {t(item.label)}
              </Link>
            </NavbarItem>
          ))}
        </ul>
      </NavbarContent>

      <NavbarContent className="basis-1 pl-4 sm:hidden" justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>

      <NavbarMenu>
        <div className="mx-4 mt-2 flex flex-col gap-2">
          {siteConfig.navItems.map((item, index) => (
            <NavbarMenuItem
              key={`${item}-${index}`}
              onClick={() => setIsMenuOpen(false)}
            >
              <Link
                color="primary"
                href={item.href}
                onClick={(e) => handleNavClick(e, item.href)}
                aria-label={item.ariraLabel}
                className="text-primary"
              >
                {t(item.label)}
              </Link>
            </NavbarMenuItem>
          ))}
        </div>
      </NavbarMenu>
    </NextUINavbar>
  );
};
