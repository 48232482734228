import React from "react";
import styles from "./AnimatedCard.module.scss";
import { HydraCard } from "@/components/HydraCard";
import { before } from "node:test";

interface AnimatedCardProps {
  rotation?: number;
  rotationHover?: number;
  afterRotation?: number;
  children?: React.ReactNode;
  hasMaxW?: boolean;
  className?: string;
  shadow?: boolean;
  hydraCardClassName?: string;
  beforeBorderColor?: string;
  beforeBackgroundColor?: string;
}

export const AnimatedCard: React.FC<AnimatedCardProps> = ({
  rotation = 0,
  rotationHover = 12,
  afterRotation = 25,
  children,
  hasMaxW = true,
  className = "",
  shadow = false,
  hydraCardClassName = "",
  beforeBorderColor = "",
  beforeBackgroundColor = "",
}) => {
  const getRotationClass = (angle: number) =>
    angle >= 0 ? `rotate-${angle}` : `-rotate-${Math.abs(angle)}`;

  const rotationClass = getRotationClass(rotation);
  const hoverClass = `hover:rotate-${rotationHover}`;
  const afterRotationClass = getRotationClass(afterRotation);

  return (
    <div
      className={`${styles[rotationClass]} ${hasMaxW ? styles.card : styles.cardNoMax} ${styles[hoverClass]} ${className} `}
    >
      <div
        className={`${styles.before} ${styles[afterRotationClass]}`}
        style={{
          borderColor: beforeBorderColor,
          backgroundColor: beforeBackgroundColor,
        }}
      />
      <HydraCard
        hasMaxW={hasMaxW}
        hasShadow={shadow}
        className={`${hydraCardClassName}`}
      >
        {children}{" "}
      </HydraCard>
    </div>
  );
};
