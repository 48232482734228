"use client";

import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState, forwardRef } from "react";
import axios from "axios";

import { Textarea } from "@heroui/input";
import { Input } from "@heroui/react";
import { Chip } from "@heroui/react";

import { HydraCard } from "@/components/HydraCard";
import { HydraButton } from "@/components/HydraButton";

import CheckmarkIcon from "@/components/Icons/CheckmarkIcon";

interface RepoType {
  email: string;
  repo: string;
  message: string;
  robot?: string;
}

interface SignUpFormProps {
  className?: string;
}

const SignUpForm = ({ className }: SignUpFormProps) => {
  const t = useTranslations("SignUpForm");
  const errorMessages = {
    url: "Please enter a valid GitHub URL",
    invalidBounty: "Please enter a valid bounty amount",
  };
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const github_issue_regex = new RegExp("https://github.com/[^/]+/[^/]+/", "g");
  const emailschema = z.object({
    email: z.string().email("Invalid email address"),
    repo: z.optional(
      z.string().regex(github_issue_regex, {
        message: errorMessages.url,
      }),
    ),
    message: z.optional(z.string()),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<RepoType>({
    resolver: zodResolver(emailschema),
    defaultValues: {},
  });

  async function onSubmit(data: RepoType) {
    try {
      const email_response: any = await axios.post(
        "/api/internalnotification/",
        { ...data, contact_type: "signup" }
      );
      if (email_response.status === 200) {
      setSubmitted(true);
      } else {
        setSubmitError(email_response?.message);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  return (
    <div className={className}>
      <HydraCard className={submitted && "rounded-full"} hasShadow={true}>
        {!submitted ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            aria-describedby="signup-for-newsletter-form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div style={{ display: "none" }}>
              <Input
                id="amount"
                aria-describedby={"signup-important-field"}
                type="text"
                {...register("robot")}
                placeholder="Please fill out this field"
                hidden
                classNames={{
                  label: "text-background dark:text-background",
                  input: [
                    "text-background dark:text-background",
                    "placeholder:text-background dark:placeholder:text-background",
                  ],
                }}
              />
              {errors.robot && (
                <Chip color="danger">{errors.robot.message}</Chip>
              )}
            </div>
            <Textarea
              id="message"
              label={t("message-input-label")}
              {...register("message")}
              variant="bordered"
              minRows={10}
              classNames={{
                label: `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.message ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                innerWrapper: `text-background dark:text-background ${errors.message ? "text-danger dark:text-danger" : ""}`,
                inputWrapper: [
                  `text-background dark:text-background border-background p-[1rem] rounded-[25px] ${errors.message ? "text-danger border-danger" : ""}`,
                  "data-[hover=true]:border-background/50",
                  "group-data-[focus=true]:border-background",
                ],
                input: [
                  `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.message ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                  "w-[20rem] max-w-full",
                  `placeholder:text-background/2 dark:placeholder:text-background/2 ${errors.message ? "placeholder:text-danger/2 dark:placeholder:text-danger/2" : ""}`,
                  `group-data-[has-value=true]:text-background dark:group-data-[has-value=true]:text-background ${errors.message ? "group-data-[has-value=true]:text-danger dark:group-data-[has-value=true]:text-danger" : ""}`,
                ],
              }}
            />
            {errors.message && (
              <Chip color="danger">{errors.message.message}</Chip>
            )}
            {submitError && <Chip color="danger">{submitError}</Chip>}
            <Input
              id="email"
              aria-describedby={"signup-email-field"}
              type="email"
              {...register("email")}
              label={t("email-input-label")}
              variant="bordered"
              classNames={{
                label: `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.email ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                innerWrapper: `text-background dark:text-background ${errors.email ? "text-danger dark:text-danger" : ""}`,
                inputWrapper: [
                  `text-background dark:text-background border-background rounded-[25px] p-[1rem] ${errors.email ? "text-danger border-danger" : ""}`,
                  "data-[hover=true]:border-background/50",
                  "group-data-[focus=true]:border-background",
                ],
                input: [
                  `text-background dark:text-background group-data-[filled-within=true]:text-background dark:group-data-[filled-within=true]:text-background ${errors.email ? "text-danger group-data-[filled-within=true]:text-danger dark:group-data-[filled-within=true]:text-danger" : ""}`,
                  "w-[20rem] max-w-full",
                  `placeholder:text-background/2 dark:placeholder:text-background/2 ${errors.email ? "placeholder:text-danger/2 dark:placeholder:text-danger/2" : ""}`,
                  `group-data-[has-value=true]:text-background dark:group-data-[has-value=true]:text-background ${errors.email ? "group-data-[has-value=true]:text-danger dark:group-data-[has-value=true]:text-danger" : ""}`,
                ],
              }}
            />
            {errors.email && <Chip color="danger">{errors.email.message}</Chip>}
            {submitError && <Chip color="danger">{submitError}</Chip>}
            <div className="my-4 flex justify-end">
              <HydraButton
                className="w-1/2"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                aria-describedby="signup-for-newsletter-button"
                autoFocus={false}
                color="gradient"
                hover="secondary"
              >
                {t("button-contact")}
              </HydraButton>
            </div>
          </form>
        ) : (
          <div className="flex w-full flex-col items-center justify-center">
            <CheckmarkIcon
              className="h-[4rem] w-[4rem]"
              filled
              fill={"#FDF9ED"}
            />
          </div>
        )}
      </HydraCard>
    </div>
  );
};
SignUpForm.displayName = "SignUpForm";
export default SignUpForm;
