export type Profile = {
  imageUrl: string;
  name: string;
  position: string;
  quote: string;
  githubUrl?: string;
  xUrl?: string;
  repository?: {
    name: string;
    url: string;
  };
};

export const testimonialCompaniesProfiles: Profile[] = [
  {
    name: "Carlo Mazzaferro",
    position: "Senior Backend Engineer @ Worldcoin",
    quote:
      "I'm constantly impressed by Hydra's great taste in niche technical talent, meaning less work for me screening candidates.",
    imageUrl: "/CompaniesProfiles/carlo.png",
    githubUrl: "https://github.com/carlomazzaferro",
    xUrl: "https://x.com/carlomazzaferro",
  },
  {
    name: "Alex Obadia",
    position: "Co-founder @ Flashbots",
    quote:
      "Hydra has the most relevant, talented developers for Rust crypto projects.",
    imageUrl: "/CompaniesProfiles/alex.png",
    githubUrl: "https://github.com/obadiaa",
    xUrl: "https://x.com/obadiaalex",
  },
  {
    name: "Jonas Bostoen",
    position: "CTO @ Chainbound",
    quote:
      "The Hydra team knows the Rust open source ecosystem inside and out.",
    imageUrl: "/CompaniesProfiles/jonas.png",
    githubUrl: "https://github.com/mempirate",
    xUrl: "https://x.com/mempirate",
  },
  {
    name: "Matty Taylor",
    position: "Head of Growth @ Solana",
    quote:
      "As former developers, the Hydra team is a step above other recruiting agencies. They live and breathe technology.",
    imageUrl: "/CompaniesProfiles/matty.png",
    xUrl: "https://x.com/mattytay",
  },
];

export const testimonialDevelopersProfiles: Profile[] = [
  {
    name: "Leonid Logvinov",
    position: `Former Software Engineer @ MakerDAO, Contributor to `,
    quote:
      "I learn something new about the Rust ecosystem every time I talk a Hydra team member.",
    imageUrl: "/DevelopersProfiles/Leonid.png",
    githubUrl: "https://github.com/LogvinovLeon",
    repository: {
      name: "/tokio",
      url: "https://github.com/tokio-rs/tokio",
    },
  },

  {
    name: "Rushil Mehra",
    position: "Software Engineer @ Cloudflare, Contributor to ",
    quote: "The Hydra team has connections to every Rust shop around.",
    imageUrl: "/DevelopersProfiles/Rushil.jpeg",
    githubUrl: "https://github.com/rushilmehra",
    repository: {
      name: "/boring",
      url: "https://github.com/cloudflare/boring",
    },
  },
  {
    name: "Greg Hysen",
    position: "Senior Protocol Engineer @ 0x",
    quote: "It's refreshing to only share my GitHub with a recruiter.",
    imageUrl: "/DevelopersProfiles/Greg.png",
    githubUrl: "https://github.com/hysz",
  },

  {
    name: "Petar Dambovaliev",
    position: "Maintainer of ",
    quote:
      "Hydra gets my needs as an open source developer, and helped me find a job.",
    imageUrl: "/DevelopersProfiles/Petar.png",
    githubUrl: "https://github.com/petar-dambovaliev",
    repository: {
      name: "/darpi-rs",
      url: "https://github.com/darpi-rs/darpi",
    },
  },
];
