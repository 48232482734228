export const host = process.env.VERCEL_URL
  ? `${process.env.NEXT_PUBLIC_VERCEL_URL}`
  : `http://localhost:${3000}`;

export const defaultLocale = "en" as const;
export const locales = ["en"] as const; //add de and fr here

export const pathnames = {
  "/": "/",
  "/about/": {
    en: "/about",
    de: "/ueber-uns",
    fr: "/a-propos",
  },
  "/blog/": {
    en: "/blog/",
    de: "/blog/",
    fr: "/blog/",
  },
  "/jobs/": {
    en: "/jobs/",
    de: "/jobs/",
    fr: "/jobs/",
  },
  "/login": {
    en: "/login",
    de: "/anmelden",
    fr: "/connexion",
  },
  "/test": {
    en: "/test",
    de: "/test",
    fr: "/test",
  },
  "/contact/": {
    en: "/contact/",
    de: "/kontakt/",
    fr: "/contact/",
  },
  "/imprint/": {
    en: "/imprint/",
    de: "/impressum/",
    fr: "/mentions-legales/",
  },
  "/privacy/": {
    en: "/privacy/",
    de: "/datenschutz/",
    fr: "/politique-de-confidentialite/",
  },
};

// Use the default: `always`
export const localePrefix = undefined;

export type AppPathnames = keyof typeof pathnames;
