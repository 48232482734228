import { useFormatter, useTranslations } from "next-intl";
import React, { ReactNode } from "react";
import { AnimatedCard } from "../AnimatedCard";
import { Chip } from "@heroui/react";
import { HydraButton } from "../HydraButton";
import CheckmarkIcon from "../Icons/CheckmarkIcon";
import Link from "next/link";
import MagnifyingGlassIcon from "../Icons/MagnifyingGlassIcon";
import { format_location } from "@/functions/formats";

interface ApplicationCardProps {
  children?: ReactNode;
  className?: string;
}

export type Job = {
  title: string;
  min_salary: number;
  max_salary: number;
  location: string;
};

export const job: Job = {
  title: "Software Engineer Web3 Blockchain",
  min_salary: 150000,
  max_salary: 190000,
  location: "New York, United States",
};

const ApplicationCard: React.FC<ApplicationCardProps> = ({
  children,
  className = "",
}) => {
  const formatter = useFormatter();
  const t = useTranslations("Jobs");

  return (
    <div className={`relative ${className}`}>
      {/* Shadow card */}
      <div className="absolute left-[-5px] top-[1px] z-0 h-full min-h-[26rem] w-full rotate-[-2deg] rounded-2xl border-2 border-white shadow-lg lg:left-[1rem] lg:top-[2px] lg:rotate-[5deg]" />

      {/* Main card */}
      <div className="relative z-10 rounded-[1rem] border-[1rem] border-white bg-white">
        <AnimatedCard
          rotation={0}
          afterRotation={0}
          rotationHover={0}
          hasMaxW={true}
        >
          <div className="flex flex-col justify-between px-2 pb-3 pt-1 md:min-h-[25rem]">
            <div className="">
              <div className="flex w-full justify-between"></div>
              <div className="flex flex-col">
                <div className="mb-4 mt-3 flex flex-col">
                  <h1 className="text-left text-3xl font-bold text-white md:text-xl lg:text-2xl">
                    {job.title}
                  </h1>
                </div>
                <div className="flex flex-col gap-2">
                  <Chip className="bg-background text-primary">
                    <div className="flex">
                      <p>{`${job.min_salary ? formatter.number(job.min_salary / 1000) + "k" : null} - ${job.max_salary != null ? formatter.number(job.max_salary / 1000) + "k" : null}`}</p>
                    </div>
                  </Chip>

                  <Chip className="bg-background text-primary">
                    <p>{format_location(job.location)}</p>
                  </Chip>
                </div>
              </div>
            </div>
            <div className="mt-16 flex w-full max-w-full flex-wrap items-center justify-end gap-[1rem] md:mt-8">
          
                    <MagnifyingGlassIcon />
               
               
              
              <div className="h-inherit ">
                <HydraButton
                  isDisabled
                  aria-label="You already applied silly >u<"
                >
                  <div className="flex items-center">
                    <CheckmarkIcon
                      className="h-8 w-8"
                      filled
                      fill={"#6A478E"}
                    />
                    <p className="hidden sm:hidden md:hidden xl:block">
                      {t("applied")}
                    </p>
                  </div>
                </HydraButton>
              </div>
            </div>
          </div>
        </AnimatedCard>
      </div>
    </div>
  );
};

export default ApplicationCard;
